import React, { useState, useEffect } from "react";
import { Button } from ".";
import Img from "react-cool-img";
// import magazineImage from "../../images/magazine.png";

import useTags from "../../helpers/useTags";

function MagazineSection() {
    const [magazineResults, setMagazineResults] = useState([])
    const magUrl = "https://life.x-hub.io/community/GeexReview-java-Edition6.0"
    const customHook = useTags("magazine", 1);

    useEffect(() => {
        if (customHook.tagsDataResults.meta.pagination.total != 0) {
            // const newPosts = results.concat(posts)
            setMagazineResults((prevResults) => {
                return customHook.tagsDataResults;
            });
        }
    }, [customHook.tagsDataResults])

    return (
        <div id="magazine" className="relative lg:grid lg:grid-cols-2 lg:gap-x-40 flex flex-col-reverse py-56 font-body lg:items-center space-y-10">
        <div className="lg:absolute top-0 left-0 w-56 h-56 shadow-xl general--rounded-tr-4xl general--rounded-bl-4xl opacity-50"></div>
            <div className="z-10">
                <h4 className="general--h4-styles mb-6">🔖 Our magazine</h4>
                <h1 className="general--h1-styles">The developer magazine</h1>
                <p className="general--p-styles">
                    GeexReview is a quarterly digital magazine made by
                    developers for developers. Covering in every edition a
                    specific IT theme, the main purpose of this digital magazine
                    is to spread the coding culture and share the latest
                    technology topics with the community of developers and IT
                    enthusiasts. GeexReview is open for any contribution, if you
                    have any relevant content, feel free to
                    <a rel="noopener" href="mailto:geex.review@x-hub.io" className="text-primary-500 hover:text-secondary-500 hover:no-underline font-semibold dark:text-secondary-500"> get in touch 📧</a>
                </p>
                <div className="text-right mt-10">
                    <Button
                        external={true}
                        linkedTo={magUrl}
                        buttonClass="general--button"
                    >
                    Read More
                    </Button>
                </div>
            </div>
            {magazineResults.map((result) => 
            <Img style={{ backgroundColor: "grey", width: "480", height: "320" }}key={result.id} src={result.feature_image} alt={result.name} />
            ) }
            <div className="lg:absolute hidden bottom-0 right-0 w-56 h-56 shadow-xl general--rounded-tr-4xl general--rounded-bl-4xl opacity-50"></div>
        </div>
    );
}

export default MagazineSection;
