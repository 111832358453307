import React from "react";

function Spinner() {
    return (
        <div>
            <div className="flex justify-center py-32">
                <svg
                    className="animate-spin w-12 h-12"
                    width="497"
                    height="497"
                    viewBox="0 0 497 497"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        opacity="0.2"
                        d="M448.944 362.881L421.114 346.981C417.89 345.101 415.429 342.149 414.161 338.64C412.892 335.131 412.897 331.287 414.174 327.781C399.946 357.437 378.029 382.736 350.705 401.046C323.38 419.356 291.647 430.007 258.809 431.889C225.971 433.772 193.229 426.818 163.991 411.75C134.753 396.683 110.088 374.054 92.5645 346.218C75.041 318.383 65.2994 286.359 64.3538 253.481C63.4082 220.602 71.2931 188.072 87.1878 159.275C103.082 130.478 126.406 106.469 154.729 89.7463C183.053 73.0235 215.342 64.1988 248.234 64.1909C254.124 64.1909 259.944 64.4809 265.694 65.0209C264.954 64.9509 264.214 64.8709 263.464 64.8109C254.974 64.1209 248.234 57.5009 248.234 48.9809V16.9809C248.23 12.8494 249.825 8.87662 252.684 5.89431C255.543 2.912 259.446 1.15134 263.574 0.980918C258.474 0.650918 253.414 0.190918 248.234 0.190918C111.234 0.190918 0.233582 111.191 0.233582 248.191C0.233582 385.191 111.234 496.191 248.234 496.191C346.234 496.191 430.654 439.241 470.944 356.771C466.814 364.631 456.714 367.321 448.944 362.881V362.881Z"
                        fill="url(#paint0_linear)"
                    />
                    <path
                        d="M263.464 64.8109C254.974 64.1209 248.234 57.5009 248.234 48.9809V16.9209C248.234 7.81085 255.904 0.140851 265.004 0.750852C394.154 9.37085 496.234 116.861 496.234 248.191C496.262 285.705 487.711 322.729 471.234 356.431C467.234 364.601 456.864 367.431 448.974 362.881L421.134 346.981C413.724 342.751 411.304 333.631 414.934 325.911C426.349 301.593 432.256 275.055 432.234 248.191C432.234 151.701 357.964 72.5609 263.464 64.8109Z"
                        fill="url(#paint1_linear)"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear"
                            x1="-235.121"
                            y1="248.191"
                            x2="260.2"
                            y2="718.257"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop  />
                            <stop offset="1"  />
                        </linearGradient>
                        <linearGradient
                            id="paint1_linear"
                            x1="372.234"
                            y1="-181.449"
                            x2="33.2852"
                            y2="49.2724"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop  stop-color="#BD7AE3" />
                            <stop offset="1"  stop-color="#8461C9" />
                        </linearGradient>
                    </defs>
                </svg>
            </div>
        </div>
    );
}

export default Spinner;
