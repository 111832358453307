import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";

const NavigationTags = ({ data, className, id }) => {
    const tags = data.allGhostTag.edges;
    const filteredTags = tags.filter(function ({ node }) {
        switch (node.name.toLowerCase()) {
            case "java":
            case "docker":
            case "data":
            case "spring":
            case "react":
            case "scrum":
                return true;
                break;
            default:
                return false;
        }
    });
    return (
        <div className="flex flex-col" className={className}>
            <h3 className="font-semibold text-black dark:text-white text-opacity-30">
                xTechnologies
            </h3>
            {filteredTags.map(({ node }) => {
                return (
                    <div className="flex  justify-between"  key={node.id}>
                        <a rel="noopener" href={node.url} className="xlog-link dark:text-white">
                            {node.name.toLowerCase()}
                        </a>
                        <span className="font-semibold">
                            {node.count.posts}
                        </span>
                    </div>
                );
            })}
        </div>
    );
};

const NavigationTagsQuery = (props) => (
    <StaticQuery
        query={graphql`
            query NavigationTagsQuery {
                allGhostTag(sort: { order: ASC, fields: name }) {
                    edges {
                        node {
                            name
                            id
                            url
                            count {
                                posts
                            }
                        }
                    }
                }
            }
        `}
        render={(data) => <NavigationTags data={data} {...props} />}
    />
);

export default NavigationTagsQuery;
