import React, { useState, useEffect  } from "react";
import { Helmet } from "react-helmet";
import {  StaticQuery, graphql } from "gatsby";
import { Navigation, Footer } from ".";

// Styles

import "../../styles/main.css";
import "../../styles/app.css";

/**
 * Main layout component
 *
 * The Layout component wraps around each page and template.
 * It also provides the header, footer as well as the main
 * styles, and meta data for each page.
 *
 */
const DefaultLayout = ({ data, children, isPost }) => {
    const site = data.allGhostSettings.edges[0].node;
    const twitterUrl = site.twitter
        ? `https://twitter.com/${site.twitter.replace(/^@/, ``)}`
        : null;
    const facebookUrl = site.facebook
        ? `https://www.facebook.com/${site.facebook.replace(/^\//, ``)}`
        : null;
        const [theme, setTheme] = useState();

        useEffect(() => {
            if(!localStorage.getItem("theme")){
              localStorage.setItem("theme", "dark")
            }else{
              setTheme(localStorage.getItem("theme"))
            }
           // paint();
          },[theme, setTheme])

          const handleDarkMode = () => {
            // console.log("handle dark mode",{theme})
    
           if(theme === "dark") {
                //document.querySelector('html').classList.remove('dark')
                setTheme("light")
                localStorage.setItem("theme", "light")
           } else if (theme === "light"){
                //document.querySelector('html').classList.add('dark')
                setTheme("dark")
                localStorage.setItem("theme", "dark")}
    
        }
        
    return (
        <>
            <Helmet>
                <html lang={site.lang} className={theme}/>
                <style type="text/css">{`${site.codeinjection_styles}`}</style>
                <body className="bg-white dark:bg-black"/>
            </Helmet>
            <Navigation data={site} isPost={isPost} handleDarkMode={handleDarkMode} layouatTheme={theme} />
            <div className="lg:container lg:mx-auto lg:px-8 px-6">
            
            {/* All the main content gets inserted here, index.js, post.js */}
            <main className="py-10">{children}</main>

            </div>
            <Footer />
        </>
    );
};

const DefaultLayoutSettingsQuery = (props) => (
    <StaticQuery
        query={graphql`
            query GhostSettings {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
                file(relativePath: { eq: "ghost-icon.png" }) {
                    childImageSharp {
                        fixed(width: 30, height: 30) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `}
        render={(data) => <DefaultLayout data={data} {...props} />}
    />
);

export default DefaultLayoutSettingsQuery;
