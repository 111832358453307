import React from "react";
import { Link } from "gatsby";
import { readingTime as readingTimeHelper } from "@tryghost/helpers";

const PostCard = ({ post, filter }) => {
    const url = `/${post.slug}/`;
    const readingTime = readingTimeHelper(post);
    const publishedAt = new Date(post.published_at).toLocaleDateString();
    const authorUrl = `author/${post.primary_author.slug}/`;

    return (
        <div className="bg-white dark:bg-black  transition ease-out rounded-t-3xl rounded-bl-3xl shadow-md lg:transform md:transform hover:shadow-2xl lg:hover:scale-105 md:hover:scale-105 overflow-hidden font-body z-10">
            <Link to={url} className="hover:no-underline  text-white  hover:text-secondary-500">
                <header className="relative xl:h-ph lg:h-sph h-sph">
                    <div
                        className="h-full w-full bg-cover bg-no-repeat bg-center"
                        style={{
                            backgroundImage: `url(${post.feature_image})`,
                        }}
                    ></div>
                    <div className="bg-black w-full h-full bottom-0 left-0 absolute bg-opacity-50"></div>
                    {filter ? <div className="general--tag">{post.primary_tag.name}</div> : null}
                    <h2 className="absolute bottom-0 md:px-10 md:py-10 left-0 px-16 py-16 xl:text-5xl lg:text-4xl md:text-5xl text-4xl font-semibold leading-none ">
                        {post.title}
                    </h2>
                </header>
            </Link>
            <section className="md:px-10 xl:px-20 md:py-10 sm:px-16 sm:pt-16 sm:pb-8 xl:pt-20 xl:pb-8 py-16 px-16 text-xl dark:text-white text-gray-500 mb-10 sm:mb-5">
                <p className="xl:text-2xl">
                    {post.excerpt.substring(0, 200)}...
                </p>
            </section>
            <footer className="flex px-16 py-8 justify-between content-end">
                <div className="flex space-x-4 justify-start">
                    <div className="">
                        {post.primary_author.profile_image ? (
                            <img
                                className="xl:w-20 w-14 xl:h-20 h-14 rounded-b-full rounded-tl-full"
                                src={post.primary_author.profile_image}
                                alt={post.primary_author.name}
                            />
                        ) : (
                            <img
                                className=""
                                src="/images/icons/avatar.svg"
                                alt={post.primary_author.name}
                            />
                        )}
                    </div>
                    <div>
                        <Link to={authorUrl} className="hover:no-underline">
                            <h4 className="text-gray-700 dark:text-white font-semibold xl:text-2xl sm:text-2xl">
                                {post.primary_author.name}
                            </h4>
                        </Link>
                        <h4 className="text-gray-900 dark:text-white mt-2 xl:text-xl sm:text-xl">
                        {publishedAt}
                    </h4>
                    </div>
                </div>
                <div className="text-right">
                    <div className="text-gray-900 dark:text-white font-semibold xl:text-xl sm:text-xl">
                    {post.reading_time}min 📖
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default PostCard;
