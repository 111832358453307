import { result } from "lodash";
import React, { useState, useEffect } from "react";
import { SearchCards, Button } from ".";
import usePosts from "../../helpers/usePosts";



function SearchSection() {
    const [results, setResults] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [matched, setMatched] = useState([]);
    const [onSearch, setOnSearch] = useState(false);
    
    
    const customHook = usePosts(9999, "false", 1);

    useEffect(() => {
        if (customHook.postsDataResults.meta.pagination.total != 0) {
            // const newPosts = results.concat(posts)
            setResults((prevResults) => {
                return prevResults.concat(customHook.postsDataResults);
            });
        } else {
            console.log('');
        }
    }, [customHook.postsDataResults])

    const searchChange = (e) => {
        const searchValue = e.target.value;

        if (searchValue != "") {
            setOnSearch(true);
            setSearchResults(
                results.filter((result) => {
                    const regex = new RegExp(`${searchValue}`, "gi");
                    setMatched(regex)
                    if (result.title.match(regex) || result.html.match(regex)) {
                        return result;
                    }
                })
            );
        } else {
            setOnSearch(false);
            setSearchResults([]);
        }
    };
    
    return (
        <>
                <div className="px-6 lg:py-80 pb-32 pt-48 lg:w-10/12 lg:mx-auto">
                    <h1 className="general--h1-styles">Sharing knowledge is our DNA 🧬</h1>
                    <p className="general--p-styles mb-8">
                        Because power is gained by sharing knowledge not by
                        hoarding it, our experts will be covering the best and
                        latest IT practices to level up your IT learning
                        journey... <br></br>Enjoy reading
                    </p>
                    <div className="flex flex-col justify-center w-full mx-auto relative">
                        <form>
                            <input
                                className="bg-white shadow-lg w-full  py-5 px-6 rounded-lg mr-4 focus:shadow-xl"
                                onChange={searchChange}
                                type="text"
                                name="query"
                                placeholder="Search for topics... 🔎"
                                autoComplete="off"
                            />
                            {/* <button type="submit">
                                <Button buttonClass="bg-purple hover:bg-secondary flex items-center">
                                    <svg
                                        className="w-6 mr-2"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                        />
                                    </svg>
                                    Search
                                </Button>
                            </button> */}
                        </form>
                        <div
                            className={
                                onSearch
                                    ? "block text-left transition-opacity ease-in opacity-100 absolute top-32 bg-white dark:bg-black shadow-2xl py-10 border-gray-400 border z-30 px-6 rounded-lg mr-4 w-full max-h-ph overflow-y-auto"
                                    : " hidden opacity-0"
                            }
                        >
                            {searchResults.length > 0 ? (
                                <>
                                    <div>
                                        {/* card */}
                                        <h1 className="text-4xl dark:text-secondary-500 font-body">
                                            Results 🕵
                                        </h1>
                                        {/* <div>
                                            <h4>Related Tags</h4>
                                            {searchResults.slice(0, 4).map((result) => (
                                                <> 
                                                    <Button buttonClass="bg-primary-500 py-2 px-6 rounded text-white  font-semibold outline-none focus:outline-none">
                                                        {result.primary_tag}
                                                    </Button>
                                                </>
                                            )) }
                                        </div> */}
                                        {searchResults
                                            .slice(0, 3)
                                            .map((result) => (
                                                <>
                                                    <SearchCards
                                                        key={result.id}
                                                        results={result}
                                                        queryMatch={matched}
                                                    />
                                                    <div className="border-b-2 border-solid border-gray-100 border-opacity-10"></div>
                                                </>
                                            ))}
                                    </div>
                                </>
                            ) : (
                                <div>
                                    <h1 className="text-4xl dark:text-secondary-500 font-body">
                                        Nothing was found... 👀
                                    </h1>
                                    <div>
                                        <p>Maybe try something else?</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
        </>
    );
}

export default SearchSection;
