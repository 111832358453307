import React, { useState, useEffect } from "react";
import { Button, AdvocacyCard } from ".";
import usePosts from "../../helpers/usePosts";

function Events() {
    const [eventResults, setEventResults] = useState([])
    const tagUrl = "https://blog.x-hub.io/tag/events"
    let filter = "events";
    let pageNumber = 1
    const customHook = usePosts(2, filter, pageNumber);

    useEffect(() => {
        if (customHook.postsDataResults.meta.pagination.total != 0) {
            // const newPosts = results.concat(posts)
            setEventResults((prevResults) => {
                return customHook.postsDataResults;
            });
        }
    }, [customHook.postsDataResults])

    return (
        <div id="events" className="relative lg:grid lg:grid-cols-2 lg:gap-x-40 flex flex-col-reverse py-56 font-body lg:items-center gap-y-10">
        <div className="lg:absolute hidden top-0 right-0 w-56 h-56 shadow-xl general--rounded-tr-4xl general--rounded-tl-4xl opacity-50"></div>
            <div>
                <h4 className="general--h4-styles mb-6">🥳 Events</h4>
                <h1 className="general--h1-styles">We believe in the power of community</h1>
                <p className="general--p-styles">
                    Connecting with the developer community, networking &
                    sharing is one of the most important engagements. our team
                    is speaking & attending conferences to enable developers of
                    all levels to connect with people with similar interests in
                    technology and to learn new skills.
                    <br></br> 
                    We believe in the community that's why all our actions are
                    meant to improve it through our organized events and many
                    more actions.
                </p>
                <div className="text-right mt-10">
                    <Button
                        external={false}
                        linkedTo={tagUrl}
                        buttonClass="general--button"
                    >
                        Read More
                        </Button>
                </div>
            </div>
            <div>
                <div className="grid lg:grid-cols-2 md:grid-cols-2 xl:gap-x-16 lg:gap-x-6 gap-x-12 gap-y-16 ">
                    {eventResults.map((eventResult) => (
                        <AdvocacyCard key={eventResult.id} post={eventResult} />
                    ))}
                </div>
            </div>
            <div className="lg:absolute hidden bottom-0 left-0 w-56 h-56 shadow-xl general--rounded-bl-4xl opacity-50"></div> 
        </div>
    );
}

export default Events;
