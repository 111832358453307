import React from "react";
import Img from "react-cool-img";

import {
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img19,
    img18,
    img20,
    img22,
    img23,
    img24,
    img230,
} from "../../images/Tech/";

function Header({ children }) {
    return (
        <div className="py-20">
            <div className="flex flex-row text-center">
                <div className="lg:grid gap-y-4 grid-cols-2 gap-x-4 w-4/12 hidden">
                    <div className="header--box-styles w-48 h-48 lg:w-40 lg:h-40 xl:h-48 xl:w-48  2xl:h-60 2xl:w-60 general--rounded-br-4xl z-10 shadow-xl  ">
                        <Img 
                            src={img1}
                            alt={img1}
                            className="opacity-0 hover:opacity-100 transition duration-700 ease-in-out  lg:w-64 lg:h-full object-contain object-center"
                        />
                    </div>
                    <div className="header--box-styles w-48 h-48 lg:w-40 lg:h-40 xl:h-48 xl:w-48  2xl:h-60 2xl:w-60 "></div>
                    <div className="header--box-styles w-48 h-48 lg:w-40 lg:h-40 xl:h-48 xl:w-48  2xl:h-60 2xl:w-60 "></div>
                    <div className="header--box-styles w-48 h-48 lg:w-40 lg:h-40 xl:h-48 xl:w-48  2xl:h-60 2xl:w-60  general--rounded-tr-4xl shadow-xl">
                        <Img 
                            src={img2}
                            alt={img1}
                            className="opacity-0 hover:opacity-100 transition duration-700 ease-in-out  lg:w-64 lg:h-full object-contain object-center"
                        />
                    </div>
                    <div className="header--box-styles w-48 h-48 lg:w-40 lg:h-40 xl:h-48 xl:w-48  2xl:h-60 2xl:w-60  general--rounded-bl-4xl z-10 shadow-xl  ">
                        <Img 
                            src={img3}
                            alt={img1}
                            className="opacity-0 hover:opacity-100 transition duration-700 ease-in-out  lg:w-64 lg:h-full object-contain object-center"
                        />
                    </div>
                    <div className="header--box-styles w-48 h-48 lg:w-40 lg:h-40 xl:h-48 xl:w-48  2xl:h-60 2xl:w-60  rounded-full z-10 shadow-xl  ">
                        <Img 
                            src={img4}
                            alt={img1}
                            className="opacity-0 hover:opacity-100 transition duration-700 ease-in-out  lg:w-64 lg:h-full object-contain object-center"
                        />
                    </div>
                    <div className="header--box-styles w-48 h-48 lg:w-40 lg:h-40 xl:h-48 xl:w-48  2xl:h-60 2xl:w-60   general--rounded-br-4xl z-10 shadow-xl">
                        <Img 
                            src={img10}
                            alt={img1}
                            className="opacity-0 hover:opacity-100 transition duration-700 ease-in-out  lg:w-96 lg:h-full object-contain object-center"
                        />
                    </div>
                    <div className="header--box-styles w-48 h-48 lg:w-40 lg:h-40 xl:h-48 xl:w-48  2xl:h-60 2xl:w-60 "></div>
                </div>
                <div>{children}</div>
                <div className="lg:grid grid-cols-2 gap-x-4 gap-y-4 w-4/12 hidden">
                    <div className="header--box-styles w-48 h-48 lg:w-40 lg:h-40 xl:h-48 xl:w-48  2xl:h-60 2xl:w-60   general--rounded-br-4xl z-10 shadow-xl  ">
                        <Img 
                            src={img5}
                            alt={img1}
                            className="opacity-0 hover:opacity-100 transition duration-700 ease-in-out  lg:w-64 lg:h-full object-contain object-center"
                        />
                    </div>
                    <div className="header--box-styles w-48 h-48 lg:w-40 lg:h-40 xl:h-48 xl:w-48  2xl:h-60 2xl:w-60  rounded-full z-10 shadow-xl">
                        <Img 
                            src={img6}
                            alt={img1}
                            className="opacity-0 hover:opacity-100 transition duration-700 ease-in-out  lg:w-64 lg:h-full object-contain object-center"
                        />
                    </div>
                    <div className="header--box-styles w-48 h-48 lg:w-40 lg:h-40 xl:h-48 xl:w-48  2xl:h-60 2xl:w-60   general--rounded-br-4xl z-10 shadow-xl">
                        <Img 
                            src={img7}
                            alt={img1}
                            className="opacity-0 hover:opacity-100 transition duration-700 ease-in-out  lg:w-64 lg:h-full object-contain object-center"
                        />
                    </div>
                    <div className="header--box-styles w-48 h-48 lg:w-40 lg:h-40 xl:h-48 xl:w-48  2xl:h-60 2xl:w-60 "></div>
                    <div className="header--box-styles w-48 h-48 lg:w-40 lg:h-40 xl:h-48 xl:w-48  2xl:h-60 2xl:w-60 "></div>
                    <div className="header--box-styles w-48 h-48 lg:w-40 lg:h-40 xl:h-48 xl:w-48  2xl:h-60 2xl:w-60  general--rounded-tr-4xl shadow-xl   ">
                        <Img 
                            src={img8}
                            alt={img1}
                            className="opacity-0 hover:opacity-100 transition duration-700 ease-in-out  lg:w-64 lg:h-full object-contain object-center"
                        />
                    </div>
                    <div className="header--box-styles w-48 h-48 lg:w-40 lg:h-40 xl:h-48 xl:w-48  2xl:h-60 2xl:w-60  general--rounded-bl-4xl z-10 shadow-xl  ">
                        <Img 
                            src={img9}
                            alt={img1}
                            className="opacity-0 hover:opacity-100 transition duration-700 ease-in-out  lg:w-64 lg:h-full object-contain object-center"
                        />
                    </div>
                </div>
            </div>
            <div className="w-4/12 mx-auto lg:block hidden pb-5">
                <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="far"
                    data-icon="mouse-alt"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                    className="h-10 w-10 mx-auto opacity-25 animate-bounce dark:text-white"
                >
                    <path
                        fill="currentColor"
                        d="M224 0h-64A160 160 0 0 0 0 160v192a160 160 0 0 0 160 160h64a160 160 0 0 0 160-160V160A160 160 0 0 0 224 0zm112 352a112.12 112.12 0 0 1-112 112h-64A112.12 112.12 0 0 1 48 352V160A112.12 112.12 0 0 1 160 48h64a112.12 112.12 0 0 1 112 112zM192 96a32 32 0 0 0-32 32v32a32 32 0 0 0 64 0v-32a32 32 0 0 0-32-32z"
                        
                    ></path>
                </svg>
            </div>
            <div className="lg:flex space-x-6 hidden justify-between">
                <div className=" bg-white dark:bg-black lg:h-64 lg:w-64 overflow-hidden general--rounded-tr-4xl  z-10 shadow-xl">
                <Img 
                        src={img20}
                        alt={img18}
                        className="opacity-0 hover:opacity-100 transition duration-700 ease-in-out  lg:w-96 lg:h-full object-cover object-center "
                    />
                </div>
                <div className=" bg-white dark:bg-black lg:h-64 lg:w-64 overflow-hidden general--rounded-bl-4xl general--rounded-br-4xl z-10 shadow-xl">
                    <Img 
                        src={img19}
                        alt={img18}
                        className="opacity-0 hover:opacity-100 transition duration-700 ease-in-out  lg:w-96 lg:h-full object-cover object-center"
                    />
                </div>
                <div className=" bg-white dark:bg-black lg:h-64 lg:w-64 overflow-hidden general--rounded-br-4xl general--rounded-tl-4xl z-10 shadow-xl">
                    <Img 
                        src={img24}
                        alt={img1}
                        className="opacity-0 hover:opacity-100 transition duration-700 ease-in-out  lg:w-96 lg:h-full object-cover object-center"
                    />
                </div>
                <div className="bg-white dark:bg-black lg:h-64 lg:w-64 overflow-hidden rounded-full z-10 shadow-xl">
                    <Img 
                        src={img22}
                        alt={img18}
                        className="opacity-0 hover:opacity-100 transition duration-700 ease-in-out  lg:w-96 lg:h-full object-cover object-center"
                    />
                </div>
                <div className="bg-white dark:bg-black lg:h-64 lg:w-64 overflow-hidden general--rounded-br-4xl z-10 shadow-xl">
                    <Img 
                        src={img230}
                        alt={img18}
                        className="opacity-0 hover:opacity-100 transition duration-700 ease-in-out  lg:w-96 lg:h-full object-cover object-center"
                    />
                </div>
                <div className="bg-white dark:bg-black lg:h-64 lg:w-64 overflow-hidden general--rounded-br-4xl general--rounded-tl-4xl z-10 shadow-xl">
                    <Img 
                        src={img18}
                        alt={img18}
                        className="opacity-0 hover:opacity-100 transition duration-700 ease-in-out  lg:w-96 lg:h-full object-cover object-center"
                    />
                </div>
                <div className="header--box-styles lg:h-64 lg:w-3/12 general--rounded-br-4xl z-10 shadow-xl">
                    <ul className="list-none h-full flex justify-center items-center px-12 space-x-10 content-center">
                        <li>
                            <a rel="noopener" href="https://www.linkedin.com/company/xhub/" target="_blank">
                                <svg
                                    className="h-10 text-primary-300 hover:text-primary-500 transition ease-out"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fab"
                                    data-icon="linkedin-in"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                                    ></path>
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a rel="noopener" href="https://www.facebook.com/xhub.io" target="_blank">
                                <svg
                                    className="h-10 text-primary-300 hover:text-primary-500 transition ease-out"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fab"
                                    data-icon="facebook-f"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 320 512"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                                    ></path>
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a rel="noopener" href="https://www.instagram.com/xhub.io/" target="_blank">
                                <svg
                                    className="h-10 text-primary-300 hover:text-primary-500 transition ease-out"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fab"
                                    data-icon="instagram"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                                    ></path>
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a rel="noopener" href="https://twitter.com/xhubio" target="_blank">
                                <svg
                                    className="h-10 text-primary-300 hover:text-primary-500 transition ease-out"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fab"
                                    data-icon="twitter"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                                    ></path>
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a rel="noopener" href="https://www.youtube.com/channel/UC6W4WmBXD5J5M8X9yMe6GYQ" target="_blank">
                                <svg
                                    className="h-10 text-primary-300 hover:text-primary-500 transition ease-out"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fab"
                                    data-icon="youtube"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
                                    ></path>
                                </svg>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Header;
