import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { NavigationTags } from ".";
import Img from "react-cool-img";

import ReadingProgress from 'react-reading-progress'

import menuIcon from "../../images/bars-solid.svg"
import logo from "../../images/logo.svg"

const Navigation = ({ data, isPost, handleDarkMode, layouatTheme}) => {
    const [buttonClass, setButtonClass] = useState("bg-white")
    
    useEffect(() => {
        if (layouatTheme === "dark") {
            setButtonClass("bg-yellow-400 -left-2")
        }else if (layouatTheme === "light") {
            setButtonClass("bg-white left-10")
        }
    }, [layouatTheme]) 

    const handleDarkModeButton = () => {
        if (layouatTheme === "dark") {
            setButtonClass("bg-red-400")
        }else if (layouatTheme === "light") {
            setButtonClass("bg-blue-400")
        }
    }
    const site = data;

    return (
        <div className="fixed z-50 w-full bg-white dark:bg-black shadow-sm ">
            {/* Nav start*/}
            <div className="flex  flex-wrap justify-between items-center lg:container lg:mx-auto lg:px-8 py-4  px-6 lg:py-4">
                    <Link to="/" className="hover:no-underline ">
                <div className="flex-1 flex justify-between items-center">
                        {/* {site.logo ? (
                            <Img src={site.logo} alt={site.title} className="w-24"/>
                        ) : (
                            <Img
                                className="w-36"
                                fixed={data.file.childImageSharp.fixed}
                                alt={site.title}
                            />
                        )} */}
                        <Img src={logo} alt={site.title} className="w-16"/>
                        <p className="font-bold text-primary-500 hover:text-secondary-500 ml-2 text-2xl dark:text-white">BLOG</p>
                </div>
                    </Link>

                {/* Nav mobile button*/}
                <label
                    for="menu-toggle"
                    className="cursor-pointer lg:hidden block"
                >
                    <Img src={menuIcon} alt={menuIcon} className="w-7"/>
                    
                </label>
                <input type="checkbox" className="hidden" id="menu-toggle" />
               
                {/* Nav Links*/}
                <div
                    className="hidden lg:flex lg:items-center lg:w-auto w-full lg:py-0 py-6"
                    id="menu"
                >
                    
                    <div className="lg:flex lg:flex-row lg:space-x-40 lg:items-center lg:justify-between flex flex-col-reverse">
                        {/* Border */}
                        <div className="border-b-2 border-solid border-gray-100 pt-6 lg:hidden"></div>
                        <div className="lg:space-x-32 flex flex-col lg:flex lg:flex-row lg:pb-0 pb-6 pt-12 lg:pt-0 space-y-4 lg:space-y-0">
                            <a rel="noopener"  
                                href="https://blog.x-hub.io/#topics"
                                className="xlog-link dark:text-white"
                            >
                                Topics 🗞️
                            </a>
                            <a rel="noopener"  
                                href="https://blog.x-hub.io/#magazine"
                                className="xlog-link dark:text-white"
                            >
                                Our magazine 🔖
                            </a>
                            <a rel="noopener"  
                                href="https://blog.x-hub.io/#events"
                                className="xlog-link dark:text-white"
                            >
                                Events 🥳
                            </a>
                            <a rel="noopener"  
                                href="https://blog.x-hub.io/"
                                className="xlog-link dark:text-white"
                                href="https://www.x-hub.io/"
                                target="_blank"
                            >
                                About Us 🐙
                            </a>
                        </div>
                        {/* Border */}
                        <div className="border-b-2 border-solid border-gray-100 pb-6 lg:hidden"></div>
                        {/* Dark Mode Button*/}
                            <div className="flex lg:space-x-6 space-x-4 items-center py-6 lg:py-0 ">
                                <div className="font-semibold  dark:text-white">{layouatTheme === "dark" ? 'Light Mode' : 'Dark Mode'} : </div>
                                <div className="relative w-20 h-10 bg-blue-300 rounded-full dark:bg-black">
                                    <div className={`absolute w-12 h-12  rounded-full transition-all ease-in  shadow-md -bottom-1 cursor-pointer ${buttonClass}`} onClick={handleDarkModeButton, handleDarkMode}>
                                </div>
                                <div className="flex justify-between px-2">
                                    <div>🌞</div>
                                    <div>🌜</div>
                                </div>
                                    {/* {layouatTheme === "dark" ? '🌜' : '🌞'} */}
                                {/* <label className="switch w-20 h-12 relative block">
                                    <input type="checkbox" onClick={handleDarkMode}/>
                                    <span className="slider round absolute cursor-pointer border-solid border-black border-2"></span>
                                </label> */}
                            </div>
                        </div>
                        
                    </div>
                     {/* Tags Menu*/}
                    <NavigationTags className="lg:hidden flex flex-col pt-6 lg:pt-0 space-y-4 lg:space-y-0"/>
                </div>
            </div>
            {isPost &&
                <ReadingProgress targetEl="#target-el"/> 
            }
        </div>
    );
};
export default Navigation;


    // const slider = document.getElementsByClassName("slider");
    // useEffect(() => {
    //     if(layouatTheme === "light") {
            
    //         slider.pseudoStyle("before","transform","translateX(8%)");
    //     }else if (layouatTheme === "dark"){
    //         slider.pseudoStyle("before","transform","translateX(92%)");
    // }
    // }, [])

    
    // const paint = () => {
    //         if(theme === "dark"){
    //             document.querySelector('html').classList.add('dark')
    //         }else{
    //             document.querySelector('html').classList.remove('dark')
    //         }

    //         console.log({html : document.querySelector("html").classList })
    //         console.log("in paint..................",theme)
    // } 

    // useEffect(() => {
    //   if(!localStorage.getItem("theme")){
    //     localStorage.setItem("theme",theme)
    //   }else{
    //     setTheme(localStorage.getItem("theme"))
    //   }
    //  // paint();
    // },[theme,setTheme,paint])



    
        // useEffect(() => {
        //     if (localStorage.getItem('theme') === "dark" || theme === "dark") {
        //         document.querySelector('html').classList.add('dark')
        //         localStorage.setItem("theme", "dark")
        //     } else if (localStorage.getItem('theme') === "light") {
        //         document.querySelector('html').classList.remove('dark')
        //         localStorage.setItem("theme", "light")
        //     }
        // }, [])

  
    // const handleDarkMode = () => {
    //     console.log("handle dark mode",{theme})

    //    if(theme === "dark") {
    //         //document.querySelector('html').classList.remove('dark')
    //         setTheme("light")
    //         localStorage.setItem("theme", "light")
    //    } else if (theme === "light"){
    //         //document.querySelector('html').classList.add('dark')
    //         setTheme("dark")
    //         localStorage.setItem("theme", "dark")}

    //         themeChanged(theme)
    // }
    // console.log(layouatTheme)