import React from "react";
import { Link } from "gatsby";
import { readingTime as readingTimeHelper } from "@tryghost/helpers";

function AdvocacyCard({ post }) {
    const url = `/${post.slug}/`;
    const readingTime = readingTimeHelper(post);
    const publishedAt = new Date(post.published_at).toLocaleDateString();
    return (
        <div className="bg-white transition ease-out rounded-t-3xl rounded-bl-3xl shadow-md lg:transform md:transform hover:shadow-2xl lg:hover:scale-105 md:hover:scale-105 overflow-hidden font-body">
            <Link to={url} className="hover:no-underline text-gray-600">
                <header className="relative xl:h-ph lg:h-sph h-sph">
                    <div
                        className="h-full w-full bg-cover bg-no-repeat bg-center"
                        style={{
                            backgroundImage: `url(${post.feature_image})`,
                        }}
                    ></div>
                    <div className="bg-black w-full h-full bottom-0 left-0 absolute bg-opacity-70"></div>
                    <div className="general--tag">{post.primary_tag.name}</div>
                    <div className="absolute bottom-0 md:px-10 md:py-10 left-0 px-16 py-16">
                        <h2 className="advocacy--h2-styles">
                            {post.title}
                        </h2>
                        <div className="flex justify-end space-x-7">
                            <h4 className="advocacy--h4-styles">
                                {post.primary_author.name}
                            </h4>
                            <div className="advocacy--small-text">
                                {readingTime}
                            </div>
                            <div className="advocacy--small-text">
                                {publishedAt}
                            </div>
                        </div>
                    </div>
                </header>
            </Link>
        </div>
    );
}

export default AdvocacyCard;
