import React from "react";

const Footer = () => {
    return (
        <div className="font-body bg-primary-900 text-white">
            <div className="lg:container lg:mx-auto flex lg:flex-row flex-col justify-between items-start lg:px-10 px-28 pt-20 pb-10 ">
                <div>
                    <div>
                        <svg
                            width="220"
                            height="41"
                            viewBox="0 0 220 41"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M58.5023 15.4612H54.1768V26.8128H51.2673V15.4612H47V13.1872H58.5023V15.4612Z"
                                fill="white"
                            />
                            <path
                                d="M71.663 26.8128H68.7535V20.9733H63.0799V26.8128H60.1704V13.1872H63.0799V18.7086H68.7535V13.1872H71.663V26.8128Z"
                                fill="white"
                            />
                            <path
                                d="M82.7095 20.9078H77.1232V24.5575H83.6793V26.8128H74.2137V13.1872H83.6599V15.4612H77.1232V18.7086H82.7095V20.9078Z"
                                fill="white"
                            />
                            <path
                                d="M101.418 22.2741C101.308 23.7402 100.745 24.8944 99.7302 25.7366C98.7215 26.5789 97.3896 27 95.7344 27C93.9241 27 92.4984 26.4135 91.4575 25.2406C90.423 24.0615 89.9057 22.4456 89.9057 20.393V19.5602C89.9057 18.25 90.1449 17.0958 90.6234 16.0976C91.1018 15.0994 91.784 14.3351 92.6698 13.8048C93.562 13.2683 94.5965 13 95.7732 13C97.4026 13 98.7151 13.4211 99.7108 14.2634C100.706 15.1056 101.282 16.2879 101.437 17.8102H98.5276C98.4565 16.9305 98.2011 16.2941 97.7614 15.9011C97.3282 15.5018 96.6655 15.3021 95.7732 15.3021C94.8034 15.3021 94.076 15.639 93.5911 16.3128C93.1126 16.9804 92.867 18.0192 92.854 19.4291V20.4586C92.854 21.9309 93.0835 23.0071 93.5426 23.6872C94.0081 24.3672 94.7387 24.7072 95.7344 24.7072C96.6332 24.7072 97.3024 24.5107 97.742 24.1176C98.1881 23.7184 98.4435 23.1038 98.5082 22.2741H101.418Z"
                                fill="white"
                            />
                            <path
                                d="M114.928 20.3088C114.928 21.6502 114.682 22.8262 114.19 23.8369C113.699 24.8476 112.994 25.6275 112.076 26.1765C111.165 26.7255 110.117 27 108.934 27C107.764 27 106.719 26.7286 105.801 26.1858C104.883 25.643 104.172 24.8694 103.668 23.865C103.163 22.8543 102.908 21.6939 102.902 20.3837V19.7099C102.902 18.3685 103.15 17.1894 103.648 16.1725C104.153 15.1493 104.861 14.3663 105.772 13.8235C106.69 13.2745 107.738 13 108.915 13C110.091 13 111.135 13.2745 112.047 13.8235C112.965 14.3663 113.673 15.1493 114.171 16.1725C114.675 17.1894 114.928 18.3654 114.928 19.7005V20.3088ZM111.979 19.6912C111.979 18.2625 111.714 17.1769 111.184 16.4345C110.654 15.6921 109.897 15.3209 108.915 15.3209C107.938 15.3209 107.185 15.6889 106.655 16.4251C106.125 17.1551 105.856 18.2282 105.85 19.6444V20.3088C105.85 21.7001 106.115 22.7794 106.645 23.5468C107.175 24.3142 107.938 24.6979 108.934 24.6979C109.91 24.6979 110.66 24.3298 111.184 23.5936C111.708 22.8512 111.973 21.7718 111.979 20.3556V19.6912Z"
                                fill="white"
                            />
                            <path
                                d="M117.042 26.8128V13.1872H121.387C122.628 13.1872 123.737 13.4586 124.713 14.0013C125.696 14.5379 126.462 15.3053 127.012 16.3035C127.561 17.2955 127.836 18.4247 127.836 19.6912V20.3182C127.836 21.5847 127.565 22.7108 127.021 23.6965C126.485 24.6823 125.725 25.4465 124.742 25.9893C123.76 26.5321 122.651 26.8066 121.416 26.8128H117.042ZM119.951 15.4612V24.5575H121.358C122.496 24.5575 123.365 24.1988 123.966 23.4813C124.568 22.7638 124.875 21.7375 124.888 20.4024V19.6818C124.888 18.2968 124.59 17.2487 123.996 16.5374C123.401 15.82 122.531 15.4612 121.387 15.4612H119.951Z"
                                fill="white"
                            />
                            <path
                                d="M138.456 20.9078H132.87V24.5575H139.426V26.8128H129.96V13.1872H139.406V15.4612H132.87V18.7086H138.456V20.9078Z"
                                fill="white"
                            />
                            <path
                                d="M150.501 21.3476L148.988 22.9198V26.8128H146.079V13.1872H148.988V19.3636L150.269 17.6698L153.867 13.1872H157.445L152.431 19.242L157.591 26.8128H154.128L150.501 21.3476Z"
                                fill="white"
                            />
                            <path
                                d="M163.914 21.8249H161.596V26.8128H158.687V13.1872H163.934C165.602 13.1872 166.888 13.5459 167.794 14.2634C168.699 14.9808 169.151 15.9947 169.151 17.3048C169.151 18.2344 168.941 19.0111 168.521 19.635C168.107 20.2527 167.477 20.7455 166.63 21.1136L169.685 26.6818V26.8128H166.562L163.914 21.8249ZM161.596 19.5508H163.943C164.674 19.5508 165.24 19.373 165.64 19.0174C166.041 18.6555 166.242 18.1595 166.242 17.5294C166.242 16.8868 166.051 16.3815 165.67 16.0134C165.295 15.6453 164.716 15.4612 163.934 15.4612H161.596V19.5508Z"
                                fill="white"
                            />
                            <path
                                d="M179.344 24.0053H174.243L173.273 26.8128H170.179L175.436 13.1872H178.132L183.418 26.8128H180.324L179.344 24.0053ZM175.029 21.7313H178.559L176.784 16.631L175.029 21.7313Z"
                                fill="white"
                            />
                            <path
                                d="M189.159 21.3476L187.646 22.9198V26.8128H184.737V13.1872H187.646V19.3636L188.926 17.6698L192.524 13.1872H196.103L191.089 19.242L196.249 26.8128H192.786L189.159 21.3476Z"
                                fill="white"
                            />
                            <path
                                d="M205.84 20.9078H200.254V24.5575H206.81V26.8128H197.345V13.1872H206.791V15.4612H200.254V18.7086H205.84V20.9078Z"
                                fill="white"
                            />
                            <path
                                d="M220 26.8128H217.09L211.427 17.8476V26.8128H208.517V13.1872H211.427L217.1 22.1711V13.1872H220V26.8128Z"
                                fill="white"
                            />
                            <path
                                d="M15.2778 21.0743C14.9224 21.0743 14.5885 21.1732 14.3821 21.4299L0.208453 39.0327C-0.312107 39.68 0.208453 40.5729 1.10418 40.5729H9.02746C9.38468 40.5729 9.71855 40.4007 9.92498 40.1424L18.7386 29.147L23.7611 22.8362L21.0093 28.8839L30.0276 40.1424C30.234 40.4007 30.5679 40.5713 30.9251 40.5713H38.8484C39.7441 40.5713 40.2665 39.6513 39.7441 39.004L25.3174 20.9914C25.111 20.7347 24.7771 20.6199 24.4217 20.6199L15.2778 21.0743Z"
                                fill="white"
                            />
                            <path
                                d="M39.7472 1.5449L25.5736 19.1541C25.3672 19.4108 25.0315 19.5097 24.6761 19.5097L15.5304 19.9625C15.4029 19.9625 15.2773 19.9466 15.1606 19.9163L18.9391 11.7112L16.1927 17.7478L21.2152 11.4353L30.0289 0.436754C30.2353 0.180046 30.571 0.00146696 30.9264 0.00146696H38.8515C39.7472 -0.000127495 40.2677 0.897553 39.7472 1.5449Z"
                                fill="white"
                            />
                            <path
                                d="M18.6591 11.343L18.9696 11.7384L15.1965 19.9244C15.1785 19.9196 15.1229 19.9036 15.1031 19.8973C15.0762 19.8877 15.0511 19.8797 15.0223 19.8686C14.9057 19.8255 14.7764 19.7617 14.6777 19.6517C14.2846 19.2276 13.8179 18.5691 13.7676 18.5053L13.0675 17.6315L8.31608 11.6986L0.206109 1.57373C0.188159 1.55141 0.170208 1.52909 0.157643 1.50677C0.127128 1.46053 0.101997 1.41269 0.0786616 1.36645C-0.050581 1.07786 -0.0146803 0.765341 0.143283 0.511822C0.172003 0.463988 0.202519 0.420938 0.23842 0.381076C0.434079 0.153068 0.73744 0 1.10363 0H9.02871C9.38413 0 9.7198 0.176985 9.92623 0.433693L17.968 10.4788L18.6591 11.343Z"
                                fill="white"
                            />
                        </svg>
                    </div>
                    <div>
                        <h3 className="leading-none text-2xl font-semibold text-white mb-8">
                        💼 Offices
                        </h3>
                        <p>
                            We’re remote friendly, with office locations around
                            the world: <br></br>
                            🌍 Casablanca, Agadir, Valencia,
                             Quebec
                        </p>
                    </div>
                </div>
                <div>
                    <h3 className="leading-none text-2xl font-semibold text-white mb-8">
                    📞 Contact Us:
                    </h3>
                    <ul className="list-none">
                        <li className="flex justify-between mb-3">
                            <span>
                                <svg
                                    className="w-10"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                </svg>
                            </span>
                            <a rel="noopener" href="mailto:info@x-hub.io" target="_blank" className="text-white hover:text-primary-500 transition ease-out hover:no-underline">
                                info@x-hub.io
                            </a>
                        </li>
                        <li className="flex justify-between mb-3">
                            <span>
                                <svg
                                    className="w-10"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </span>
                            <a rel="noopener" href="https://www.x-hub.io/" target="_blank" className="text-white hover:text-primary-500 transition ease-out hover:no-underline">
                                www.x-hub.io
                            </a>
                        </li>
                        <li className="flex justify-between mb-3">
                            <span>
                                <svg
                                    className="w-10"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                                </svg>
                            </span>
                            +212520524792
                        </li>
                    </ul>
                </div>
                <div>
                    <h3 className="leading-none text-2xl font-semibold text-white mb-8">
                    🤳🏻 Follow us:
                    </h3>
                    <ul className="list-none flex justify-between space-x-8 items-end">
                        <li>
                            <a rel="noopener" href="https://www.linkedin.com/company/xhub/" target="_blank">
                                <svg
                                    className="h-10 text-white hover:text-primary-500 transition ease-out"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fab"
                                    data-icon="linkedin-in"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                                    ></path>
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a rel="noopener" href="https://www.facebook.com/xhub.io" target="_blank">
                                <svg
                                    className="h-10 text-white hover:text-primary-500 transition ease-out"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fab"
                                    data-icon="facebook-f"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 320 512"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                                    ></path>
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a rel="noopener" href="https://www.instagram.com/xhub.io/" target="_blank">
                                <svg
                                    className="h-10 text-white hover:text-primary-500 transition ease-out"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fab"
                                    data-icon="instagram"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                                    ></path>
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a rel="noopener" href="https://twitter.com/xhubio" target="_blank">
                                <svg
                                    className="h-10 text-white hover:text-primary-500 transition ease-out"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fab"
                                    data-icon="twitter"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                                        
                                    ></path>
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a rel="noopener" href="https://www.youtube.com/channel/UC6W4WmBXD5J5M8X9yMe6GYQ" target="_blank">
                                <svg
                                    className="h-10 text-white hover:text-primary-500 transition ease-out"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fab"
                                    data-icon="youtube"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
                                        
                                    ></path>
                                </svg>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="border-b-2 border-solid border-gray-100 border-opacity-10"></div>
            <div className="flex justify-between lg:container lg:mx-auto items-start lg:px-10 px-28 py-4">
                <div>
                    <p>
                        © XHUB. All rights reserved.
                    </p>
                </div>
                <div>
                    <p>
                        Made with 💜 by xHub
                    </p>
                </div>
                <div>
                    <a rel="noopener" href="#" className="text-white hover:text-primary-500 transition ease-out hover:no-underline">
                        Terms of Service
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Footer;
