import React from "react";
import { Link } from "gatsby";
import Img from "react-cool-img";

function SearchCards({ results, queryMatch }) {
    // queryMatch is kept for search highlight, v2
    
    const url = `/${results.slug}/`;
    return (
        <>
            <div className="grid grid-cols-3 gap-6 py-14 px-6">
                <div className="">
                    <Img src={results.feature_image} className="w-full rounded-xl" alt="" />
                </div>
                <div className="col-span-2">
                    <Link to={url} className="hover:no-underline dark:text-white text-gray-600">
                    <h3 className="m-0 dark:text-secondary-500 dark:hover:text-primary-500 hover:text-secondary-500">{results.title}</h3>
                    <p className="general--p-styles m-0">{results.excerpt.substring(0, 150)}...</p>
                    </Link>
                </div>
            </div>
            
        </>
    );
}

export default SearchCards;
