import React from "react";
import { Link } from "gatsby";

function Button({ buttonClass, buttonName, linkedTo, onClick, children, external }) {
    return (
        <div className="">
            {linkedTo != undefined ? (
                <Link to={linkedTo}  target={external ? "_blank" : "_self"}>
                    <button onClick={onClick} className={"py-4 font-body hover:shadow-lg px-12 transition ease-out text-white rounded-lg  " + buttonClass}>
                        {children}
                        {buttonName}
                    </button>
                </Link>
            ) : (
                <button onClick={onClick} className={"py-4 font-body hover:shadow-lg px-12 transition ease-out text-white rounded-lg  " + buttonClass}>
                    {children}
                    {buttonName}
                </button>
            )}
        </div>
    );
}

export default Button;
